.blog {
  &__single-post {
    box-shadow: 0px 1px 15px 0px $color-shadow;
    border-radius: 5px;
    overflow: hidden;
    &:hover {
      .blog__single-post__image {
        img {
          transform: scale(1.1);
        }
      }
    }
    &__image {
      overflow: hidden;
      img {
        transition: 0.3s;
      }
    }
    &__body {
      padding: 30px;
      background-color: $color-light;
      @include respond(laptop) {
        padding: 10px;
      }
      @include respond(tablet) {
        padding: 20px;
      }
    }
    &__content {
      padding-bottom: 15px;
      h2 {
        a {
          color: $color-primary;
          &:hover {
            color: $color-secondary;
          }
        }
      }
    }
    &__meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $color-border;
      padding-top: 15px;
      svg {
        color: $color-primary;
        font-size: 20px;
      }
      ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        li:not(:last-child) {
          margin-right: 10px;
        }
        li {
          list-style: none;
          font-size: 20px;
          color: $color-primary;
          cursor: pointer;

          a {
            display: flex;
            text-transform: uppercase;
            align-items: center;
          }
        }
      }
    }
  }
}
