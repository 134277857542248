// Testimonial
.testi-row {
  overflow: hidden;
  .clients-slider {
    padding: 0 0 50px 0;
  }
  .swiper-pagination {
    &-bullet {
      background-color: $color-primary;
      opacity: 0.3;
      padding: 5px;
      &-active {
        opacity: 1;
        padding: 6px;
      }
    }
  }
  .testi-card {
    box-shadow: 0px 1px 15px 0px $color-shadow;
    border: none;
    margin-top: 15px;
    background-color: $bg-one;

    img {
      width: 60px;
      border-radius: 50%;
    }
    p {
      font-style: italic;
    }
    &__quotation {
      text-align: center;
      svg {
        font-size: 65px;
        color: $color-primary;
      }
    }
    &__user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $color-primary;
      img {
        margin-right: 20px;
      }
    }
  }
}
.swiper-wrapper {
  margin-bottom: 60px;
}
// Testimonial 02
.testimonialv2 {
  .testi-row .testi-card img {
    width: 90px;
  }
  &__card-title {
    font-size: 25px;
    margin-top: 20px;
  }
  &__card-subtitle {
    font-size: 16px;
    color: $color-primary;
  }
}

.bg-one {
  .testi-card {
    background-color: $color-light;
    &__user-info {
      border-top: 1px solid $color-border;
    }
  }
}
