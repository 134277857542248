.booksv1 {
  &__img:not(:last-child) {
    margin-bottom: 20px;
  }
  &__img img {
    border-radius: 5px;
  }

  &__contnet {
    &--badge {
      text-transform: uppercase;
      letter-spacing: 4px;
      color: $color-primary;
      font-weight: 600;
    }
  }
  &__item {
    display: grid;
    grid-template-columns: auto 1fr;

    &--icon {
      width: 60px;
      height: 60px;
      background-color: $color-primary;
      color: $color-light;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 3px 3px $color-secondary;
      transition: 0.3s;
      font-size: 35px;
    }
    &--text {
      margin-left: 20px;
      h3 {
        transition: 0.3s;
      }
    }

    &:hover {
      .booksv1__item--text h3 {
        color: $color-primary;
      }

      .booksv1__item--icon {
        box-shadow: 0px 0px transparent;
      }
    }
  }
  &__item:not(:last-child) {
    margin-bottom: 25px;
  }
}
